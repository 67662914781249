import { FC } from 'react';
import { Text, TextProps } from '@chakra-ui/react';
import moment from 'moment';
import { isAfter, timeFromNow } from '../../helpers';
export interface CbTimeFromNowProps extends TextProps {
  endDateTime?: Date;
  isUpcoming?: boolean;
  startDateTime?: Date;
}
export const CbTimeFromNow: FC<CbTimeFromNowProps> = ({
  endDateTime,
  isUpcoming,
  startDateTime,
  ...props
}) => {
  const hasTimePassed = isAfter(moment.now(), endDateTime);
  const endDateFromNow = timeFromNow(endDateTime);
  const endDateLabel = hasTimePassed ? 'ago' : '';
  const startDateFromNow = timeFromNow(startDateTime);
  const dateFromNow = isUpcoming ? startDateFromNow : endDateFromNow;
  const dateLabel = isUpcoming ? 'till start' : endDateLabel;
  const textProps = {
    color: 'white',
    fontFamily: 'codenext',
    fontSize: {
      md: 'lg'
    },
    isTruncated: true,
    maxWidth: '100%'
  };
  if (dateFromNow !== 'Invalid date') {
    return <div>
        <Text {...textProps}>{dateFromNow}</Text>
        <Text textAlign="center" {...textProps}>{`${dateLabel}`}</Text>
      </div>;
  }
  return <Text color="text.primary" {...props} data-sentry-element="Text" data-sentry-component="CbTimeFromNow" data-sentry-source-file="CbTimeFromNow.tsx">
      {'unknown time'}
    </Text>;
};