import { FC, useMemo } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { GameType, Reward } from 'ui/types/Games';
import { formatNumber, getOrdinal, pluralize } from 'ui/helpers/numbers';
import Coins from 'ui/assets/Coins';
import RaffleTicket from 'ui/assets/RaffleTicket';
interface CbGamePrizeProps {
  gameType: GameType;
  maxPrizesToShow?: number;
  rewards: Reward[];
}
export const CbGamePrize: FC<CbGamePrizeProps> = ({
  gameType,
  rewards,
  maxPrizesToShow = null
}) => {
  const renderPrizeRank = (prizeIndex: number, renderingTickets: boolean) => <Flex alignItems={'center'} backgroundColor="luigisPants.600" borderRadius={100} color="white" fontFamily={'codenext'} fontSize="sm" height={'35px'} justifyContent={'center'}
  /* 
    When we render tickets the text is 2 x <Ticket Icon>
    But for tokens it's just 200 <Coin Icon>
    The presence of the x (for the multiplication symbol) means we need slightly less padding
  */ marginRight={renderingTickets ? 2 : 4} padding={2} width={'35px'} data-sentry-element="Flex" data-sentry-component="renderPrizeRank" data-sentry-source-file="CbGamePrize.tsx">
      {getOrdinal(prizeIndex + 1)}
    </Flex>;
  const hasHiddenRewards = !!maxPrizesToShow && rewards.length > maxPrizesToShow;
  const rewardsToRender = useMemo(() => {
    if (maxPrizesToShow && hasHiddenRewards) {
      return rewards.slice(0, maxPrizesToShow);
    }
    return rewards;
  }, [rewards, maxPrizesToShow]);
  return <Flex alignItems={'center'} backgroundColor={'#12275A'} borderRadius={16} direction="column" marginTop={4} padding={1} data-sentry-element="Flex" data-sentry-component="CbGamePrize" data-sentry-source-file="CbGamePrize.tsx">
      <Text color="cloud.500" fontSize="xs" fontWeight="bold" marginTop={2} textAlign={'center'} data-sentry-element="Text" data-sentry-source-file="CbGamePrize.tsx">
        {pluralize(rewards.length, 'Prize').toUpperCase()}
      </Text>
      <Flex width="100%" justifyContent={rewardsToRender.length > 3 ? 'space-between' : 'space-evenly'} marginY={4} flexWrap={'wrap'} paddingX={2} flexDirection={{
      base: 'column',
      md: 'row'
    }} data-sentry-element="Flex" data-sentry-source-file="CbGamePrize.tsx">
        {rewardsToRender.map((reward, index) => reward.type === 'token_jackpot' ? <Flex alignItems={'center'} key={index}>
              {gameType !== GameType.Incentive && renderPrizeRank(index, true)}
              <RaffleTicket width={9} height="auto" marginRight={2} />
              <Text color="white" fontWeight={'bold'}>
                {`x ${formatNumber(Number(reward.value))}`}
              </Text>
            </Flex> : <Flex alignItems={'center'} marginBottom={2} key={index}>
              {gameType !== GameType.Incentive && renderPrizeRank(index, false)}
              <Coins width={9} height="auto" marginRight={2} />
              <Text color="white" fontWeight={'bold'}>
                {formatNumber(Number(reward.value))}
              </Text>
            </Flex>)}
      </Flex>
      {hasHiddenRewards && <Text color="cloud.500" fontSize="xs" fontWeight="bold" textAlign={'center'}>
          {`+${rewards.length - maxPrizesToShow} more`}
        </Text>}
    </Flex>;
};